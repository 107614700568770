import React, { useState, useEffect, useRef } from 'react';
import {
    CircularProgress,
    Grid,
    TextField,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@material-ui/core';
//import Alert from '@material-ui/lab/Alert';
import { ArrowBackIos } from '@material-ui/icons';
import AddressInput from '../common/addressinput';
import { useTranslation } from 'react-i18next';
import Address from '../domain/address';
import { cloneDeep } from 'lodash';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { saveSurvey, deleteSurvey } from '../modules/surveydata';
import { useDispatch } from 'react-redux';
import SaveButton from '../common/savebutton';
import RichTextEditor from '../common/richtexteditor';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from '../common/confirmationdialog';

const useStyles = makeStyles((theme) => ({
    backArrow: {
        cursor: 'pointer',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function SurveyEditView(props) {
    const dispatch = useDispatch();
    const [survey, setSurvey] = useState();
    const [surveyEnrichment, setSurveyEnrichment] = useState();
    const [saving, setSaving] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const fetchEditorContentRef = useRef(null);
    const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

    const onCancel = () => {
        props.history.goBack();
    };

    const onSave = async () => {
        setSaving(true);
        const editorHtmlContent = fetchEditorContentRef.current();
        const version = parseInt(survey.version, 10);
        const surveyNew = cloneDeep(survey);
        surveyNew.version = version + 1;
        var result = await saveSurvey(surveyNew, editorHtmlContent)(dispatch);
        if (result) {
            setSurvey(surveyNew);
            setSurveyEnrichment(editorHtmlContent);
        }
        setSaving(false);
    };

    const getEditorContentHtml = (fetchEditorContent) => {
        fetchEditorContentRef.current = fetchEditorContent;
    };

    const onAddressChange = (address) => {
        const surveyNew = cloneDeep(survey);
        surveyNew.address = address;
        setSurvey(surveyNew);
    };

    const onCommonChange = (event) => {
        const surveyNew = cloneDeep(survey);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'comment':
                surveyNew.comment = value;
                break;
            case 'survey-usage':
                surveyNew.usage = value;
                break;
            case 'survey-classification':
                surveyNew.classification = value;
                break;
            case 'survey-usage-other':
                surveyNew.propertyUsageOther = value;
                break;
            case 'name':
                surveyNew.name = value;
                break;
            default:
                break;
        }

        setSurvey(surveyNew);
    };

    const onMachineChange = (event, roomIndex, machineIndex) => {
        const surveyNew = cloneDeep(survey);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'machine-room-name':
                surveyNew.machineRooms[roomIndex].name = value;
                break;
            case 'machine-name':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].name = value;
                break;
            case 'air-volume':
                const newValue = value.toString().replace(',', '.');
                if (!/^\d*[.]?\d{0,2}$/.test(newValue)) {
                    return;
                } else if (newValue >= 0) {
                    surveyNew.machineRooms[roomIndex].machines[machineIndex].airVolume = newValue;
                }
                break;
            case 'machine-service-area':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].serviceArea = value;
                break;
            case 'machine-filter-class':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filterClass = value;
                break;
            case 'filter-efficiency':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filterEfficiency = value;
                break;
            case 'machine-filter-type':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filterType = value;
                break;
            case 'machine-heat-recovery-mode':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].heatRecoveryModel = value;
                break;
            case 'seal-type':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].selectedSeal = value;
                break;
            case 'used-seals':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].numberOfSeals = value;
                break;
            case 'machine-type':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].type = value;
                break;
            default:
                break;
        }

        setSurvey(surveyNew);
    };

    const onFilterChange = (event, roomIndex, machineIndex, filterIndex) => {
        const surveyNew = cloneDeep(survey);
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'filter-height':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].dimensionHeight = value;
                break;
            case 'filter-width':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].dimensionWidth = value;
                break;
            case 'filter-maxdepth':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].maxDepth = value;
                break;
            case 'filter-count':
                surveyNew.machineRooms[roomIndex].machines[machineIndex].filters[filterIndex].count = value;
                break;
            default:
                break;
        }

        setSurvey(surveyNew);
    };

    const onRemove = () => {
        setConfirmRemoveOpen(true);
    };

    const onDoRemove = async () => {
        await deleteSurvey(survey.id);
        setConfirmRemoveOpen(false);
        props.history.push('/surveys');
    };

    useEffect(() => {
        let surveyMod = props.survey;
        //temppu jotta voidaan käyttää address inputtia, väliaikinen ratkaisu
        surveyMod.address = new Address(props.survey.address);
        setSurvey(surveyMod);
    }, [props.survey]);

    useEffect(() => {
        setSurveyEnrichment(props.surveyEnrichment);
    }, [props.surveyEnrichment]);

    if (!survey) {
        return <CircularProgress color={'secondary'} size={'1rem'} />;
    }

    /*if (survey.locationId !== null && survey.locationId > 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <h2>
                        <ArrowBackIos onClick={onCancel} className={classes.backArrow} name="close" />
                        <span data-testid="survey-name">{survey.name}</span>
                    </h2>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="warning">{t('survey.notAllowedToEditSurvey')}</Alert>
                </Grid>
                <Footer>
                    <Grid item>
                        <Button variant="contained" color="secondary" name="btn-close" onClick={onCancel}>
                            {t('buttons.close')}
                        </Button>
                    </Grid>
                </Footer>
                <FooterPadding />
            </Grid>
        );
    }*/

    return (
        <Grid container>
            <Grid item xs={12}>
                <h2>
                    <ArrowBackIos onClick={onCancel} className={classes.backArrow} name="close" />
                    <span data-testid="survey-name">
                        {survey.name} - <em>{t('general.editing')}</em>
                    </span>
                </h2>
            </Grid>
            <Grid item xs={12}>
                <h4>{t('survey.propertyDetails')}</h4>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="name"
                    data-testid="name-input"
                    margin="normal"
                    onChange={onCommonChange}
                    label={t('survey.surveyName')}
                    value={survey.name}
                    className={classes.textField}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="comment"
                    data-testid="comment-input"
                    margin="normal"
                    onChange={onCommonChange}
                    label={t('survey.generalComment')}
                    value={survey.comment}
                    className={classes.textField}
                />
            </Grid>
            {/*TODO: main image with comments*/}
            <Grid item xs={12}>
                <h5>{t('general.address')}</h5>
                <AddressInput
                    address={survey.address}
                    addressChanged={onAddressChange}
                    idBase={'surveyAddr-'}
                    hideName={true}
                />
            </Grid>
            <Grid item xs={12}>
                <RichTextEditor
                    initialData={surveyEnrichment}
                    getEditorContentHtml={getEditorContentHtml}
                    viewOnly={false}
                />
            </Grid>
            <Grid item xs={12}>
                <h5>{t('survey.propertyUsageAndCalssification')}</h5>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl className={classes.select} data-testid="survey-usage-form-control">
                            <InputLabel id="survey-usage">{t('survey.usage')}</InputLabel>
                            <Select
                                required
                                labelId="survey-usage"
                                id="survey-usage-select"
                                name="survey-usage"
                                data-testid="survey-usage-select"
                                value={survey.usage}
                                onChange={onCommonChange}>
                                <MenuItem value={1}>{t('survey.usageOffice')}</MenuItem>
                                <MenuItem value={2}>{t('survey.usageProductionIndustrial')}</MenuItem>
                                <MenuItem value={3}>{t('survey.usageHousing')}</MenuItem>
                                <MenuItem value={4}>{t('survey.usageWarehouseOrLogistics')}</MenuItem>
                                <MenuItem value={5}>{t('survey.usageShopping')}</MenuItem>
                                <MenuItem value={6}>{t('survey.usageSports')}</MenuItem>
                                <MenuItem value={7}>{t('survey.usageOther')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {survey.usage === 7 && (
                        <Grid item xs={12}>
                            <TextField
                                name="survey-usage-other"
                                data-testid={`survey-usage-other`}
                                margin="normal"
                                onChange={onCommonChange}
                                label={t('survey.propertyUsageOther')}
                                value={survey.propertyUsageOther}
                                className={classes.textField}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl className={classes.select}>
                    <InputLabel id="survey-classification">{t('survey.classification')}</InputLabel>
                    <Select
                        required
                        labelId="survey-classification"
                        name="survey-classification"
                        id="survey-classification-select"
                        data-testid="survey-classification-select"
                        value={survey.classification}
                        onChange={onCommonChange}>
                        <MenuItem value={0}>{t('survey.undefined')}</MenuItem>
                        <MenuItem value={1}>{t('survey.classificationOda1')}</MenuItem>
                        <MenuItem value={2}>{t('survey.classificationOda2')}</MenuItem>
                        <MenuItem value={3}>{t('survey.classificationOda3')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <h4>{t('survey.machineRooms', { pcs: survey.machineRooms.length })}</h4>
            </Grid>
            {survey.machineRooms.map((machineRoom, roomIndex) => (
                <Grid container key={`machineroom_${roomIndex}`}>
                    <Grid item xs={12}>
                        <h5>
                            {t('survey.machineRoom')}: {machineRoom.name}
                        </h5>
                        <TextField
                            name="machine-room-name"
                            data-testid={`machine-room-${roomIndex}-name`}
                            margin="normal"
                            onChange={(event) => onMachineChange(event, roomIndex, null)}
                            label={t('survey.machineRoom')}
                            value={machineRoom.name}
                            className={classes.textField}
                        />
                        <br />
                        {/*TODO: Machine rooms images with comment*/}
                    </Grid>

                    {machineRoom.machines.map((machine, machineIndex) => (
                        <React.Fragment key={`machineroom_${roomIndex}_machine_${machineIndex}`}>
                            <Grid item xs={12}>
                                <h5>
                                    {t('survey.machine')}: {machine.name}
                                </h5>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="machine-name"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-name`}
                                    margin="normal"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('survey.machine')}
                                    value={machine.name}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.select}>
                                    <InputLabel id="machine-type">{t('survey.machineType')}</InputLabel>
                                    <Select
                                        required
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-machine-type-select`}
                                        labelId="machine-type"
                                        id="machine-type"
                                        name="machine-type"
                                        value={machine.type}
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                        <MenuItem value={1}>{t('survey.machineTypeSupply')}</MenuItem>
                                        <MenuItem value={2}>{t('survey.machineTypeExhaust')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="air-volume"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-airvolume`}
                                    margin="normal"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('location.airVolume')}
                                    value={machine.airVolume}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.select}>
                                    <InputLabel id="machine-filter-class">{t('survey.filterClass')}</InputLabel>
                                    <Select
                                        required
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-class-select`}
                                        labelId="machine-filter-class"
                                        id="machine-filter-class"
                                        name="machine-filter-class"
                                        value={machine.filterClass}
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                        <MenuItem value={1}>{'ePM1'}</MenuItem>
                                        <MenuItem value={2}>{'ePM2,5'}</MenuItem>
                                        <MenuItem value={3}>{'ePM10'}</MenuItem>
                                        <MenuItem value={4}>{'Coarse'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="filter-efficiency"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-efficienty`}
                                    margin="normal"
                                    type="number"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('survey.filterEfficiency')}
                                    value={machine.filterEfficiency}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.select}>
                                    <InputLabel id="machine-filter-type">{t('survey.filterType')}</InputLabel>
                                    <Select
                                        required
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-type-select`}
                                        labelId="machine-filter-type"
                                        id="machine-filter-type"
                                        name="machine-filter-type"
                                        value={machine.filterType}
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                        <MenuItem value={1}>{t('survey.filterTypeBag')}</MenuItem>
                                        <MenuItem value={2}>{t('survey.filterTypeCartridge')}</MenuItem>
                                        <MenuItem value={4}>{t('survey.filterTypeCassetteFilter')}</MenuItem>
                                        <MenuItem value={3}>{t('survey.filterTypeOther')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {machine.type === 2 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl className={classes.select}>
                                        <InputLabel id="machine-heat-recovery-mode">
                                            {t('survey.heatRecoveryModel')}
                                        </InputLabel>
                                        <Select
                                            required
                                            data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-heat-recovery-select`}
                                            labelId="machine-heat-recovery-mode"
                                            id="machine-heat-recovery-mode"
                                            name="machine-heat-recovery-mode"
                                            value={machine.heatRecoveryModel}
                                            onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}>
                                            <MenuItem value={0}>{t('survey.heatRecoveryNone')}</MenuItem>
                                            <MenuItem value={1}>{t('survey.heatRecoveryRotating')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="machine-service-area"
                                    data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-service-area`}
                                    margin="normal"
                                    onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                    label={t('location.serviceArea')}
                                    value={machine.serviceArea}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h5>{t('survey.usedFilterDimensions')}</h5>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filters`}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                {t('survey.width')}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t('survey.height')}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t('survey.maxDepth')}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t('survey.quantity')}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {machine.filters.length === 0 && (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <em>{t('survey.noMachines')}</em>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container>
                                        {machine.filters.map((filter, filterIndex) => (
                                            <React.Fragment
                                                key={`machineroom_${roomIndex}_machine_${machineIndex}_filter_${filterIndex}`}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-width"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-width`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.dimensionWidth}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-height"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-height`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.dimensionHeight}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-maxdepth"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-maxdepth`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.maxDepth}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        name="filter-count"
                                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-filter-${filterIndex}-count`}
                                                        margin="normal"
                                                        type="number"
                                                        onChange={(event) =>
                                                            onFilterChange(event, roomIndex, machineIndex, filterIndex)
                                                        }
                                                        value={filter.count}
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <h5>{t('survey.usedSeals')}</h5>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="seal-type"
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-seal-type`}
                                        margin="normal"
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                        label={t('survey.sealType')}
                                        value={machine.selectedSeal}
                                        className={classes.textField}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="number-of-seals"
                                        data-testid={`machine-room-${roomIndex}-machine-${machineIndex}-number-of-seals`}
                                        margin="normal"
                                        type="number"
                                        onChange={(event) => onMachineChange(event, roomIndex, machineIndex)}
                                        label={t('survey.quantity')}
                                        value={machine.numberOfSeals}
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}

                    <Grid item xs={12}>
                        {/*<Button
                            id="btn-new-machine"
                            variant="contained"
                            color="secondary"
                            name="btn-new-machiner"
                            onClick={null}>
                            TODO: new machine
                        </Button>*/}
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                {/*<Button
                    id="btn-new-machine"
                    variant="contained"
                    color="secondary"
                    name="btn-new-machiner"
                    onClick={null}>
                    TODO: new machine room
                </Button>*/}
            </Grid>
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        name="poista"
                        color="secondary"
                        onClick={onRemove}
                        startIcon={<DeleteIcon />}
                        disabled={saving}>
                        {t('buttons.remove')}
                    </Button>
                </Grid>
                <Grid item>
                    <SaveButton saving={saving} disabled={saving} onSubmit={onSave} />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" name="sulje" onClick={onCancel} disabled={saving}>
                        <i className="fas fa-window-close"></i>&nbsp;{t('buttons.close')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
            <ConfirmationDialog
                cancel={() => setConfirmRemoveOpen(false)}
                confirm={onDoRemove}
                open={confirmRemoveOpen}
                confirmText={t('buttons.remove')}
                useDelay={true}>
                {t('survey.confirmRemove')}
            </ConfirmationDialog>
        </Grid>
    );
}
